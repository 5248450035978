﻿/*#region EVENT (S) */

.event {

    #pnlDate, #pnlCountdown {
        padding: 10px 0;
        display: flex;
        align-items: center;

        i {
            margin-right: 15px;
        }

        .date {
            font-size: rem-calc(18);
        }

        .timeValue {
            font-size: rem-calc(15);
            display: block;
            color: scale-color($body-font-color, $lightness: 50%);
        }
    }

    #pnlDate {
        padding-top: 0;
    }
}

/*#endregion */

/*#region PERSON(S) */

.person {

    @include breakpoint(small only) {
        font-size: 90%;
        margin-bottom: 10px;
    }

    #pnlRightCol {
        i[class*='fa-'] {
            width: 25px;
            vertical-align: baseline;
            margin-bottom: 10px;
        }
    }

    a {
        i[class*='fa-'] {
            color: $anchor-color;
        }

        &:hover [class*='fa-'] {
            color: $anchor-color-hover;
        }
    }

    .fa-calendar {
        margin-right: 7px;
    }
}

/*#endregion */

/*#region SERP */

.serp {
    .sticky-menu {
        @extend %responsive-padding;


        @include breakpoint(medium) {
            font-size: rem-calc(20);
        }

        padding-top: 10px;

        .menu {
            li {
                margin: 4px 0;

                &:first-child {
                    margin-bottom: 10px;
                }
            }

            a {
                padding-top: 6px;
                padding-bottom: 6px;


                &.is-active {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
    }

    h5 {
        text-align: left;
        font-weight: $normal;
        font-size: rem-calc(30);
    }

    .panel {
        padding: 30px 0;

        @include breakpoint(medium) {
            &:first-child {
                padding: 0;
            }
        }
    }

    .grid-x[class*="-up-"] {
        @include flex-align($x:left);
    }

    .results {
        .panel:not(:last-child) {
            border-bottom: 1px solid $medium-gray;
        }
    }
}


/*#endregion */

/*#region ERROR */

.error {


    h1 {
        text-align: center;
        font-size: rem-calc(70);

        @include breakpoint(medium) {
            font-size: rem-calc(100);
        }
    }

    .text-large {
        margin-bottom: 4px;
        font-size: rem-calc(24);

        @include breakpoint(medium) {
            font-size: rem-calc(30);
        }
    }

    .text-small {
        font-size: rem-calc(16);
    }




    #pnlSolutions {
        font-size: rem-calc(20);
        margin: 40px 0;

        p {
            margin-bottom: 6px;
        }

        .button {
            margin: 6px;
            font-weight: $normal;

            @include breakpoint(medium) {
                font-size: rem-calc(20);
                margin: 10px;
            }
        }

        #lnkHome {
            i {
                color: inherit;
            }
        }

        i {
            padding-left: 5px;
            vertical-align: baseline;
        }
    }
}
/*#endregion */

/*#region JOB POSTING */

.jobPostings {
    #pnlBirthdate {
        label {
            line-height: 1;
        }

        .fa-asterisk {
            vertical-align: sub !important;
        }

        .not-mandatory {
            color: transparent !important;
        }
    }
}

.jobPostingsGrid {
    .date {
        display: block;
        font-size: rem-calc(15);
        color: scale-color($body-font-color, $lightness: 50%);
    }
}

.jobPosting {
    .row {
        margin-bottom: 18px;

        p, ul {
            margin-bottom: 0;
        }

        .bold {
            color: scale-color($body-font-color, $lightness: 40%);
            display: block;
            background-color: $light-gray;
            text-transform: uppercase;
            font-size: rem-calc(13);
            padding: 2px 5px;
            margin-left: -5px;
            margin-bottom: 2px;
            font-weight: $normal;
        }

        h3 {
            font-size: rem-calc(17);
            font-weight: $normal;
            margin: 10px 0 0;
        }
    }
}

.formApplication {
    #aspxChkSkils {


        label {
            width: calc(100% - 13px - 1.5rem);
        }
    }

    #pnlUpload {
        padding: 10px;
        border: 1px solid $medium-gray;
        margin-bottom: 40px;

        .token {
            padding: 5px;
            margin: 2px;
            border-radius: 7px;
        }

        table {
            margin-bottom: 0;

            tbody {
                tr {

                    &:nth-child(even) {
                        background-color: initial;
                    }
                }


                &:nth-child(odd) {
                    background-color: initial;
                }
            }

            td {
                padding: initial;
            }

            td a {
                padding: 10px;
                display: block;
            }
        }

        .button.secondary {
            font-size: rem-calc(18);
            border: 1px solid $medium-gray;
        }
    }


    #SubmitButton {
        padding: 4px 15px;
        font-size: rem-calc(16);
        margin-right: 0;
        float: right;
    }
}

/*#endregion */


/*custom*/

body {
    overflow-x: hidden;
}

%wire {
    //margin-bottom: rem-calc(8);
    display: inline-block;
    position: relative;
    padding: rem-calc(0 30);


    @include breakpoint(large) {
        &:before,
        &:after {
            @include pseudo();
            width: 50vw;
            height: rem-calc(70);
            top: 0;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:before {
            right: 100%;
            background-image: image-url("wire-sx.png");
            background-position: right center;
        }

        &:after {
            left: 100%;
            background-image: image-url("wire-dx.png");
            background-position: left center;
        }
    }
}

%top-line {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(10);
    position: relative;
    padding-top: rem-calc(10);

    &:before {
        content: "";
        width: 20%;
        max-width: rem-calc(200);
        height: 1px;
        background: $dark-gray;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

#scrollDown {
    cursor: pointer;
}

.button:not(.noStyle) {
    background: $green;
    @include background(linear-gradient(to bottom, $green 0%,$dark-green 100%));
    padding: rem-calc(8 15);
    text-transform: uppercase;
    font-weight: $bold;
    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
        padding: rem-calc(8 50);
    }

    &:after {
        @include pseudo();
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include background(linear-gradient(to bottom, $dark-green 0%,$green 100%));
        opacity: 0;
        transition: all .5s;
        z-index: -1;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

#pnlPageTitle {
    text-align: center;

    @include breakpoint(medium) {
        margin-top: rem-calc(15);
    }

    h1 {
        line-height: 1.2;

        @extend %wire;

        @include breakpoint(large) {
            /*max-width: 85%;*/
            max-width: 80vw;
        }
    }

    .titlePage {
        @extend %wire;
        margin-bottom: rem-calc(20);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(30);
        }

        h1 {
            margin-bottom: 0;

            &:before,
            &:after {
                content: unset;
            }
        }
    }

    .subhead {
        text-transform: uppercase;
        font-size: rem-calc(20);
        color: $dark-gray;
        line-height: 1.2;

        @include breakpoint(medium) {
            font-size: rem-calc(22);
        }
    }
}

#pnlDefaultBody {
    text-align: center;
    max-width: rem-calc(1000);
    padding: rem-calc(10 0);
    margin: auto;

    @include breakpoint(medium) {
        padding: rem-calc(15 0 10);
    }

    @include breakpoint(large) {
        padding: rem-calc(40 0 30);
    }
}

#pnlRightCol {
    background: $light-gray;

    #pnlLibrary {
        padding: rem-calc(20 0);

        @include breakpoint(small only) {
            padding-bottom: 0;
        }

        h6 {
            text-transform: uppercase;
            font-weight: $normal;
        }

        #pnlSingleDoc {
            .button {
                display: block;
                text-align: left;
                padding: rem-calc(10 0);
            }
        }
    }
}

.siblingsNav {
    h5 {
        @extend %top-line;
    }

    .cell {
        /*max-height: rem-calc(150);*/
        /*@include breakpoint(xxlarge) {
            .lnkItemWrapper {
                max-height: rem-calc(180);
            }
        }*/
        @include breakpoint(medium down) {

            &:not(.active) {
                opacity: .4;
            }
        }
    }

    .gridItemTitle {
        font-size: rem-calc(13);
    }

    .description {
        font-size: rem-calc(10);
    }
}


.articlesGrid {
    .card-custom {
        box-shadow: unset;
        @include background(linear-gradient(to bottom, $light-gray 85%, rgba($light-gray, 0) 100%));
    }

    .card-section {
        padding: .8rem;
    }

    &, * {
        transition: all .2s !important;
    }

    .image-wrapper {
        overflow: hidden;
    }

    .date-outer {
        position: relative;
        @include position($position:absolute, $top:0, $left:0 );

        &:after {
            @include pseudo();
            @include background(linear-gradient(120deg, $dark-gray 0%, $dark-gray 85%, rgba($dark-gray, 0) 85%, rgba($dark-gray, 0) 100%));
            @include position($position:absolute, $top:2px, $left:0 );
            filter: blur(1px);
            width: 98%;
            height: 105%;
            opacity: .7;
            z-index: 1;
        }

        .date {
            background: $primary-color;
            @include background(linear-gradient(120deg, $green 0%, $green 82%, rgba($green, 0) 82%, rgba($green, 0) 100%));
            color: $white;
            padding: 10px;
            padding-right: rem-calc(35);
            font-weight: $normal;
            z-index: 2;
            position: relative;
            display: block;

            &:before {
                content: "\f073";
                font-family: FontAwesome;
                font-size: 130%;
                margin-right: rem-calc(5);
            }
        }
    }

    .gridItemTitle {
        font-family: $roboto-c;
        font-weight: $bold;
        font-size: rem-calc(18);
        text-align: center;
    }

    .description {
        margin-bottom: 0;
        font-size: rem-calc(14);
    }

    .readMore {
        font-weight: $bold;
        font-size: rem-calc(14);
    }

    .card-custom:hover {

        &, * {
            transition: all .4s !important;
        }


        .gridItemTitle,
        .readMore {
            color: $anchor-color-hover;
        }

        .readMore {
            letter-spacing: 2px;
        }

        .image-wrapper {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.productsGridHome {
    overflow: hidden;

    @include breakpoint(large) {
        /*height: 130%;
        height: 130%;*/
    }

    &.grid-x {
        @include custom-block-grid($cell-number: 3, $gutter: 2px);


        @include breakpoint(large) {
            @include custom-block-grid($cell-number: 6, $gutter: 0);
        }
    }

    .cell {
        overflow: hidden;
        z-index: 2;
        position: relative;

        @include breakpoint(large) {
            transform: skew(-18deg);
            border: unset;
            border-right: 4px solid white;

            &:first-child,
            &:last-child {
                transform: scale(1.55);
                z-index: 1;

                .lnkItemWrapper {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &:nth-child(2) {
                border-left: 4px solid white;
            }
        }
    }

    .lnkItemWrapper {
        display: block;

        @include breakpoint(large) {
            transform: translateY(-50%) skew(18deg) scale(1.6);
            position: relative;
            top: 50%;
        }

        &:after {
            @include pseudo();
            background-color: rgba($extradark-gray, .2);
            @include position($position:absolute, $top:0, $left:0);
            display: block;
            width: 100%;
            height: 100%;
            transition: all .2s;

            @include breakpoint(large) {
                background-color: rgba($white, .6);
            }
        }
    }

    img {
        transition: all .2s;
    }

    p {
        margin: 0;
    }
    //il blocco è in transform scale, pertanto scaliamo anche tutti i font dal large in su
    .text {
        transform: scale(1);

        @include breakpoint(large) {
            opacity: 0;
            width: auto;
        }

        width: 100%;
        padding: rem-calc(5);
        transition: all .2s;
        @include position($position:absolute, $center:true);
        color: $white;
        text-align: center;
        font-family: $roboto-c;
        z-index: 2;

        .gridItemTitle {
            font-size: rem-calc(16);
            text-transform: uppercase;
            font-family: inherit;
            font-weight: $bold;

            @include breakpoint(medium) {
                font-size: rem-calc(24);
            }

            @include breakpoint(large) {
                font-size: rem-calc(14);
            }

            @include breakpoint(xlarge) {
                font-size: rem-calc(18);
            }

            @include breakpoint(xxlarge) {
                font-size: rem-calc(20);
            }

            @include breakpoint(1800) {
                font-size: rem-calc(25);
            }
        }

        .description {
            font-size: rem-calc(14);
            font-family: inherit;
            font-weight: $normal;
            line-height: 1.2;


            @include breakpoint(medium) {
                font-size: rem-calc(16);
            }

            @include breakpoint(large) {
                font-size: rem-calc(12);
            }
            /*  @include breakpoint(xlarge) {
                        font-size: rem-calc(10);
                    }
                    @include breakpoint(xxlarge) {
                        font-size: rem-calc(20);
                    }*/
            @include breakpoint(1800) {
                font-size: rem-calc(18);
            }
        }
    }

    .cell:hover,
    .cell.active {
        .lnkItemWrapper:after {
            background-color: rgba($extradark-gray, .3);
        }

        .text {
            opacity: 1;
        }

        img {
            transform: scale(1.2);
        }
    }
}

.certificatesGrid {
    .lnkItemWrapper {
        display: flex;
    }

    .gridItemTitle {
        display: inline-block;
        font-size: rem-calc(15);
        margin: 0;
        line-height: 1.2;
    }

    span {
        display: block;
        color: $dark-gray;
        font-size: rem-calc(12);
        line-height: 1.2;
    }

    i {
        vertical-align: text-bottom;
        margin-right: rem-calc(5);
    }
}

.personsGrid {

    &, * {
        text-transform: initial;
    }

    img {
        filter: grayscale(100%);
    }

    .image-wrapper {
        @include image-fixed-ratio(1,1, $fit: cover);
    }


    .image-overlay-content {
        @include position($position:absolute, $center:true);
        width: 100%;
        height: 100%;
        background-color: rgba($primary-color, .7);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all .5s;
    }

    .text {
        color: white;
        padding: rem-calc(10);
    }

    p {
        margin: 0;
    }

    .gridItemTitle {
        font-size: rem-calc(18);
        margin-bottom: rem-calc(20);
    }

    .personTitle,
    .description,
    .lnkMail {
        font-size: rem-calc(14);
        line-height: 1.1;
        font-weight: $light;
    }

    .personTitle {
        font-weight: $bold;
        text-transform: uppercase;
    }

    .lnkMail {
        display: block;
        color: white;
        margin-top: rem-calc(20);

        i {
            color: white;
            vertical-align: unset;
        }
    }

    .image-wrapper:hover {
        .image-overlay-content {
            opacity: 1;
        }
    }
}


.homepage {

    .banner {
        position: relative;

        .arrow {
            @include position($position:absolute, $left:50%, $bottom:2%);
            transform: translateX(-50%);
            max-height: rem-calc(30);

            @include breakpoint(small only) {
                display: none;
            }

            @include breakpoint(large) {
                max-height: rem-calc(40);
                bottom: 8%;
            }
        }

        .responsive-embed {
            margin: 0;
        }
    }

    h5 {
        font-size: rem-calc(30);

        @include breakpoint(medium) {
            font-size: rem-calc(35);
        }

        @include breakpoint(large) {
            font-size: rem-calc(60);
        }
    }

    .button {
        @include breakpoint(small only) {
            span {
                display: block;
            }
        }

        font-size: rem-calc(16);

        @include breakpoint(medium) {
            font-size: rem-calc(18);
        }

        @include breakpoint(large) {
            font-size: rem-calc(22);
        }
    }

    #pnlAboutUs {
        text-align: center;
        padding: rem-calc(20 0);

        @include breakpoint(medium) {
            padding: rem-calc(25 0);
        }

        @include breakpoint(large) {
            padding: rem-calc(40 0);
        }

        h5 {
            @extend %wire;
        }

        p {
            font-weight: $light;
            font-size: rem-calc(16);

            @include breakpoint(medium) {
                font-size: rem-calc(20);
            }

            @include breakpoint(large) {
                font-size: rem-calc(30);
            }
        }

        .button {
            margin-top: rem-calc(10);
        }
    }

    #pnlProducts {
        padding-top: rem-calc(10);
        padding-bottom: 2px;

        @include breakpoint(medium) {
            padding-top: rem-calc(6);
        }

        @include breakpoint(large) {
            padding-top: rem-calc(30);
            padding-bottom: 6%;
        }
    }

    #pnlArticles {
        background-color: $light-gray;
        @include background(linear-gradient(to bottom, $light-gray 0%, rgba($light-gray, 0) 50%));
        padding: rem-calc(30 0);


        h5 {
            margin-bottom: rem-calc(20);
        }


        @include breakpoint(medium only) {

            .articlesGrid {
                &.grid-x {
                    @include custom-block-grid($cell-number: 3, $gutter: 8px);
                }
            }
        }
    }

    #footer {
        position: relative;

        .grid-container {
            position: relative;
            z-index: 1;
        }

        &:after {
            @include breakpoint(xxlarge) {

                @include pseudo();
                width: 100%;
                height: 210%;
                background-repeat: no-repeat;
                position: absolute;
                bottom: 0;
                background-image: image-url("wire-bottom.png");
                background-position: bottom right;
                background-size: contain;
            }

            @include breakpoint(1980) {
                background-size: unset;
            }
        }
    }
}

.products {
    #pnlBody {
        @extend #pnlDefaultBody;
    }

    #pnlLibrary {
        text-align: center;
    }

    #pnlChildren {
        @include breakpoint(large) {
            padding-bottom: 6%;
        }
    }

    #pnlCertifications {
        background-color: $light-gray;
        padding: rem-calc(20 0);
        margin-bottom: rem-calc(30);

        h5 {
            text-transform: uppercase;
            font-size: rem-calc(22);
        }

        .certificatesGrid {
            margin: 0;
        }

        .lnkItemWrapper {
            @include breakpoint(small only) {
                justify-content: center;
                flex-direction: column;
                text-align: center;
            }
        }
    }
}

.product {
    #pnl3D {
        background: #f7f7f7;
        position: relative;
        z-index: 1;

        @include breakpoint(medium) {
            display: flex;
            align-items: center;
        }


        i {
            /*@include position($position:absolute, $left);*/
            color: $primary-color;
            font-size: 4rem;
            display: block;
            /*margin-top: rem-calc(120);*/
            top: 50%;
            left: calc(50% - 2rem);
            position: absolute;
            z-index: -1;

            @include breakpoint(medium) {
                top: calc(50% - 2rem);
                left: calc(50% + 25px);
            }
        }




        iframe.render3D {
            border: 0;
            width: 100%;
        }

        img.vertical {
            height: rem-calc(400);
        }
    }

    #pnlLibrary {
        background-color: $light-gray;
        padding: rem-calc(16);
        margin: rem-calc(30 0);

        h6 {
            text-transform: uppercase;
            font-size: rem-calc(22);
            text-align: center;
            font-weight: $normal;
        }

        #pnlSingleDoc {
            text-align: center;
        }

        a {
            margin-bottom: 0;
        }

        .lnkItemWrapper {
            @include breakpoint(small only) {
                justify-content: center;
                flex-direction: column;
                text-align: center;
            }
        }
    }
}

.whoWeAres {
    #pnlProducts {
        h5 {
            @extend %top-line;
        }

        .productsGrid {

            @include breakpoint(large) {
                padding-bottom: 6%;
            }
        }
    }

    #pnlPersons {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: rem-calc(30);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(120);
        }


        .personsGrid {
            &.grid-x {
                @include custom-block-grid($cell-number: 1, $gutter: 1px);


                @include breakpoint(medium) {
                    @include custom-block-grid($cell-number: 3, $gutter: 1px);
                }

                @include breakpoint(large) {
                    @include custom-block-grid($cell-number: 5, $gutter: 1px);
                }
            }
        }
    }
}

.article {
    #pnlAlbum {
        margin-bottom: 15px;
    }

    #pnlRightCol {
        background: unset;

        @include breakpoint(small only) {
            /*border: 1px solid $secondary-color;*/
            padding: 0px 10px;
            margin-bottom: 10px;
            font-size: 90%;
        }

        #imgPublisherLogo {
            margin-left: 25px;
            max-width: 110px;
        }
    }

    #pnlDatePublished {
        i {
            vertical-align: unset;
        }
    }

    #pnlBottomNextPrev {
        @extend %top-line;
        padding-top: rem-calc(40);

        @include breakpoint(small only) {
            .cell {
                text-align: center;

                a {
                    width: 80%;
                    padding: rem-calc(10) !important;
                }

                #lnkNextBottom:before {
                    left: 90%;
                }

                #lnkPrevBottom:before {
                    right: 90%;
                }
            }
        }
    }
}

.organizations {
    .organizationslist {
        display: flex;
        justify-content: space-around;
        max-width: rem-calc(1024);
        margin: auto;

        @include breakpoint(small only) {
            flex-flow: row wrap;
            justify-content: unset;

            .divItem {
                padding: rem-calc(8 0);
                width: 50%;
                border-bottom: 1px solid $primary-color;


                &:last-child {
                    border-bottom: unset;
                    border-right: 1px solid $primary-color;
                }

                &:nth-child(2n) {
                    border-right: unset;
                }
            }
        }

        .divItem {
            display: block;
            width: 100%;
            text-align: center;
            border-right: 1px solid $primary-color;
            padding: rem-calc(6);

            &:last-child {
                border-right: unset;
            }
        }

        .gridItemTitle {
            font-weight: $bold;
            font-size: rem-calc(20);
            color: $dark-gray;
            display: block;
            margin: 0;
        }

        .addresses {
            position: relative;
            margin-top: rem-calc(30);
            display: block;

            &:before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                border-bottom: 1px solid $dark-gray;
                border-left: 1px solid $dark-gray;
                transform: translateX(-50%) rotate(-45deg);
                @include position($position:absolute, $left:50%, $bottom:calc(100% + 1rem));
            }
        }


        .address {
            display: block;
            margin-bottom: rem-calc(16);
            cursor: pointer;

            &:hover {
                color: $anchor-color-hover;
            }
        }

        .city {
            color: $anchor-color;
        }

        .type {
            display: block;
            font-size: rem-calc(12);
            color: $dark-gray;
        }
    }


    #pnlPlaces {
        .message {
            //text-align: center;
            span {
                margin: 0;
                display: inline-block;
            }

            .type {
                font-weight: $bold;
                margin-bottom: rem-calc(10);
                display: block;
            }

            .city {
                color: black;
            }
        }

        #map-canvas {
            @include breakpoint(small only) {
                height: rem-calc(230) !important;
                margin-bottom: rem-calc(15);
            }

            @include breakpoint(medium only) {
                height: rem-calc(350) !important;
            }
        }
    }
}

.whereWeAre {
    .separator {
        border-width: 2px;
        border-color: $primary-color;
    }

    #requestInfoContactPage {

        @include breakpoint(small only) {
            margin-top: rem-calc(20);
        }

        fieldset {
            border: none;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
        }

        legend {
            @include header-size(h3);
            line-height: $header-lineheight;
            color: $header-color;
            font-family: $header-font-family;
            font-style: $header-font-style;
            font-weight: $global-weight-normal;
            margin-top: 10px;
        }
    }

    .company {
        /*padding: 10px 30px;
        margin: 5px 0;*/
        h3 {
            margin: 0;
            color: $dark-gray;
        }

        .tagline {
            color: $medium-gray;
            font-size: rem-calc(18);
            margin-bottom: rem-calc(24);
            display: block;
        }

        i {
            vertical-align: middle;
            margin-right: 5px;
            font-size: 1.3rem;
        }

        .blockTitle {
            font-weight: bold;
            display: block;
            color: $dark-gray;
        }

        .label {
            font-weight: $bold;
            font-size: 90%;
            background-color: unset;
            color: $body-font-color;
            padding: 0;
            text-transform: uppercase;
        }

        i {
            font-size: 1.2em;
        }

        a {
            margin-bottom: 10px;
            display: block;
        }

        .inline-block {
            margin-bottom: 10px;
        }

        .contactPoint {
            margin-top: 20px;
        }

        .flex {
            display: flex;
        }
    }

    .contactMap + .breadcrumbsContainer {
        margin-top: 0;
    }

    .contactMap {
        margin-top: 10px;
    }
}

.media {

    h1 {
        margin-bottom: rem-calc(30);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(20);
        }
    }

    .videos-block {
        margin-bottom: rem-calc(40);
        margin-top: rem-calc(30);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(25);
        }

        @include breakpoint(large) {
            margin-bottom: rem-calc(15);
        }
    }

    h3 {
        text-align: center;
    }

    .responsive-embed {
        margin-bottom: rem-calc(4);
    }

    .description {
        text-align: center;
        margin-bottom: 0;
    }

    .videosGrid {

        &.grid-x {
            @include breakpoint(medium) {
                @include custom-block-grid($cell-number: 3, $gutter: 5px);
            }

            @include breakpoint(large) {
                @include custom-block-grid($cell-number: 3, $gutter: 8px);
            }
        }
    }

    #pnlPress {

        h3 {
            text-transform: uppercase;
            margin-bottom: rem-calc(20);
        }


        .lnkPressMedia {
            display: block;
            @include image-fixed-ratio(16,9, $fit: cover);
            position: relative;
            border: 1px solid $medium-gray;


            &.link:after {
                content: "\f08e";
            }

            &.img:after {
                content: "\f00e";
            }

            &.doc:after {
                content: "\f1c1";
            }

            &:after {
                @include position($position:absolute, $center:true);
                width: 0;
                height: 0;
                background: rgba($primary-color, 0.7);
                font-family: FontAwesome;
                transition: all .2s;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 3rem;
                opacity: 0;
            }

            &:hover {

                &:after {
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                }
            }
        }



        .gridItemTitle {
            font-size: rem-calc(14);
            line-height: 1.2;
            text-transform: uppercase;
            margin-top: rem-calc(10);
            font-weight: $bold;
        }

        .summary {
            font-size: rem-calc(14);
            line-height: 1.2;
        }

        #pnlSource {
            font-size: rem-calc(12);
            margin-top: rem-calc(6);

            .ltrSourceLabel {
                color: $primary-color;
                font-weight: $bold;
            }

            a {
                color: black;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    // ------------------
    // reset tab style
    .tabs {
        border: unset;
        background: unset;
        justify-content: center;
        display: flex;
        margin-top: rem-calc(40);
    }

    .tabs-title {
        background: unset;
        font-size: rem-calc(18);
        margin: rem-calc(0 20);

        > a {
            color: $white;
            font-size: rem-calc(17);
            @extend .button:not(.noStyle);

            &:focus,
            &[aria-selected='true'] {
                opacity: .3;
            }
        }
    }

    .tabs-content {
        border: unset;
        background: unset;
    }
}
