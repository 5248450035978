@import "shared";

// CSS Transitions
// Currently only works in Webkit.
//
// * expected in CSS3, FireFox 3.6/7 and Opera Presto 2.3
// * We'll be prepared.
//
// Including this submodule sets following defaults for the mixins:
//
//     $default-transition-property : all
//     $default-transition-duration : 1s
//     $default-transition-function : false
//     $default-transition-delay    : false
//
// Override them if you like. Timing-function and delay are set to false for browser defaults (ease, 0s).

$default-transition-property: all !default;

$default-transition-duration: 1s !default;

$default-transition-function: false !default;

$default-transition-delay: false !default;

$transitionable-prefixed-values: transform, transform-origin !default;

// One or more properties to transition
//
// * for multiple, use a comma-delimited list
// * also accepts "all" or "none"

@mixin transition-property($property-1: $default-transition-property, $property-2: false, $property-3: false, $property-4: false, $property-5: false, $property-6: false, $property-7: false, $property-8: false, $property-9: false, $property-10: false) {
  @if type-of($property-1) == string {
    $property-1: unquote($property-1); }
  $properties: compact($property-1, $property-2, $property-3, $property-4, $property-5, $property-6, $property-7, $property-8, $property-9, $property-10);
  @if $experimental-support-for-webkit {
    -webkit-transition-property: prefixed-for-transition(-webkit, $properties); }
  @if $experimental-support-for-mozilla {
    -moz-transition-property: prefixed-for-transition(-moz, $properties); }
  @if $experimental-support-for-opera {
    -o-transition-property: prefixed-for-transition(-o, $properties); }
  transition-property: $properties; }

// One or more durations in seconds
//
// * for multiple, use a comma-delimited list
// * these durations will affect the properties in the same list position

@mixin transition-duration($duration-1: $default-transition-duration, $duration-2: false, $duration-3: false, $duration-4: false, $duration-5: false, $duration-6: false, $duration-7: false, $duration-8: false, $duration-9: false, $duration-10: false) {
  @if type-of($duration-1) == string {
    $duration-1: unquote($duration-1); }
  $durations: compact($duration-1, $duration-2, $duration-3, $duration-4, $duration-5, $duration-6, $duration-7, $duration-8, $duration-9, $duration-10);
  @include experimental(transition-duration, $durations, -moz, -webkit, -o, not -ms, not -khtml, official); }

// One or more timing functions
//
// * [ ease | linear | ease-in | ease-out | ease-in-out | cubic-bezier(x1, y1, x2, y2)]
// * For multiple, use a comma-delimited list
// * These functions will effect the properties in the same list position

@mixin transition-timing-function($function-1: $default-transition-function, $function-2: false, $function-3: false, $function-4: false, $function-5: false, $function-6: false, $function-7: false, $function-8: false, $function-9: false, $function-10: false) {
  $function-1: unquote($function-1);
  $functions: compact($function-1, $function-2, $function-3, $function-4, $function-5, $function-6, $function-7, $function-8, $function-9, $function-10);
  @include experimental(transition-timing-function, $functions, -moz, -webkit, -o, not -ms, not -khtml, official); }

// One or more transition-delays in seconds
//
// * for multiple, use a comma-delimited list
// * these delays will effect the properties in the same list position

@mixin transition-delay($delay-1: $default-transition-delay, $delay-2: false, $delay-3: false, $delay-4: false, $delay-5: false, $delay-6: false, $delay-7: false, $delay-8: false, $delay-9: false, $delay-10: false) {
  @if type-of($delay-1) == string {
    $delay-1: unquote($delay-1); }
  $delays: compact($delay-1, $delay-2, $delay-3, $delay-4, $delay-5, $delay-6, $delay-7, $delay-8, $delay-9, $delay-10);
  @include experimental(transition-delay, $delays, -moz, -webkit, -o, not -ms, not -khtml, official); }

// Transition all-in-one shorthand

@mixin single-transition($property: $default-transition-property, $duration: $default-transition-duration, $function: $default-transition-function, $delay: $default-transition-delay) {
  @include transition(compact($property $duration $function $delay)); }

@mixin transition($transition-1: default, $transition-2: false, $transition-3: false, $transition-4: false, $transition-5: false, $transition-6: false, $transition-7: false, $transition-8: false, $transition-9: false, $transition-10: false) {
  @if $transition-1 == default {
    $transition-1: compact($default-transition-property $default-transition-duration $default-transition-function $default-transition-delay); }
  $transitions: false;
  @if type-of($transition-1) == list and type-of(nth($transition-1, 1)) == list {
    $transitions: join($transition-1, compact($transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10), comma); }
  @else {
    $transitions: compact($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10); }
  $delays: comma-list();
  $has-delays: false;
  $webkit-value: comma-list();
  $moz-value: comma-list();
  $o-value: comma-list();
  // This block can be made considerably simpler at the point in time that
  // we no longer need to deal with the differences in how delays are treated.
  @each $transition in $transitions {
    // Extract the values from the list
    // (this would be cleaner if nth took a 3rd argument to provide a default value).
    $property: nth($transition, 1);
    $duration: false;
    $timing-function: false;
    $delay: false;
    @if length($transition) > 1 {
      $duration: nth($transition, 2); }
    @if length($transition) > 2 {
      $timing-function: nth($transition, 3); }
    @if length($transition) > 3 {
      $delay: nth($transition, 4);
      $has-delays: true; }
    // If a delay is provided without a timing function
    @if is-time($timing-function) and not $delay {
      $delay: $timing-function;
      $timing-function: false;
      $has-delays: true; }
    // Keep a list of delays in case one is specified
    $delays: append($delays, if($delay, $delay, 0s));
    $webkit-value: append($webkit-value, compact(prefixed-for-transition(-webkit, $property) $duration $timing-function));
    $moz-value: append($moz-value, compact(prefixed-for-transition(-moz, $property) $duration $timing-function $delay));
    $o-value: append($o-value, compact(prefixed-for-transition(-o, $property) $duration $timing-function $delay)); }
  @if $experimental-support-for-webkit {
    -webkit-transition: $webkit-value;
    // old webkit doesn't support the delay parameter in the shorthand so we progressively enhance it.
    @if $has-delays {
      -webkit-transition-delay: $delays; } }
  @if $experimental-support-for-mozilla {
    -moz-transition: $moz-value; }
  @if $experimental-support-for-opera {
    -o-transition: $o-value; }
  transition: $transitions; }

// coerce a list to be comma delimited or make a new, empty comma delimited list.
@function comma-list($list: ()) {
  @return join((), $list, comma); }

// Returns `$property` with the given prefix if it is found in `$transitionable-prefixed-values`.
@function prefixed-for-transition($prefix, $property) {
  @if type-of($property) == list {
    $new-list: comma-list();
    @each $v in $property {
      $new-list: append($new-list, prefixed-for-transition($prefix, $v)); }
    @return $new-list; }
  @else {
    @if index($transitionable-prefixed-values, $property) {
      @return #{$prefix}-#{$property}; }
    @else {
      @return $property; } } }

// Checks if the value given is a unit of time.
@function is-time($value) {
  @if type-of($value) == number {
    @return not not index(s ms, unit($value)); }
  @else {
    @return false; } }
