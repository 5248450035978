// =================================================================
// This file is dynamically generated by gulp-compass-imagehelper
// Do NOT edit!
// =================================================================

// Returns if the image is accessible for these image-helpers
// Note: Not part of the official compass functions, but still useful
@function image-exists($image) {
  @return map-has-key($compass-imagehelper-imagesmap, $image);
}

// Returns the width of the image found at the path supplied by $image relative to your project's images directory.
@function image-width($image) {
  @if image-exists($image) {
    $list: map-get($compass-imagehelper-imagesmap, $image);
    $width: unquote(map-get($list, 'width'));
    @if ($width and str-length($width) > 0) {
      @return number($width) * 1px;
    }
  }
  @warn "Image '#{$image}' not found.";
  @return null;
}

// Returns the height of the image found at the path supplied by $image relative to your project's images directory.
@function image-height($image) {
  @if image-exists($image) {
    $list: map-get($compass-imagehelper-imagesmap, $image);
    $height: unquote(map-get($list, 'height'));
    @if ($height and str-length($height) > 0) {
      @return number($height) * 1px;
    }
  }
  @warn "Image '#{$image}' not found.";
  @return null;
}

// Generates a path to an asset found relative to the project's images directory.
// Passing a true value as the second argument will cause only the path to be returned instead of a url() function
// The third argument is used to control the cache buster on a per-use basis. When set to false no cache buster will be used.
// When true a md5-hash of the file is appended to the url. When a string, that value will be used as the cache buster.
@function image-url($image, $only-path: false, $cache-buster: false) {
  $result: $compass-imagehelper-path-prefix + $image;

  @if not(image-exists($image)) {
    @warn "Image '#{$image}' not found.";
  } @else {
    @if (type-of($cache-buster) == string) {
      $result: $result + '?' + $cache-buster;
    }

    @if ($cache-buster == true) {
      $list: map-get($compass-imagehelper-imagesmap, $image);
      $hash: map-get($list, 'hash');
      $result: $result + '?' + $hash;
    }
  }

  $result: quote($result);

  @if ($only-path == false) {
    $result: url($result);
  }

  @return $result;
}

// Two helper functions for casting string to number by Hugo Giraudel
// http://hugogiraudel.com/2014/01/15/sass-string-to-number/
// http://sassmeister.com/gist/9647408
@function _length($number, $unit) {
  $strings: 'px' 'cm' 'mm' '%' 'ch' 'pica' 'in' 'em' 'rem' 'pt' 'pc' 'ex' 'vw' 'vh' 'vmin' 'vmax';
  $units: 1px 1cm 1mm 1% 1ch 1 pica 1in 1em 1rem 1pt 1pc 1ex 1vw 1vh 1vmin 1vmax;
  $index: index($strings, $unit);

  @if not ($index) {
    @warn "Unknown unit `#{$unit}`.";
    @return false;
  }

  @return $number * nth($units, $index);
}

@function number($string) {
  // Matrices
  $strings: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
  $numbers: 0 1 2 3 4 5 6 7 8 9;

  // Result
  $result: 0;
  $divider: 0;
  $minus: false;

  // Looping through all characters
  @for $i from 1 through str-length($string) {
    $character: str-slice($string, $i, $i);
    $index: index($strings, $character);

    @if $character == '-' {
      $minus: true;
    } @else if $character == '.' {
      $divider: 1;
    } @else {
      @if not ($index) {
        $result: if($minus, $result * -1, $result);
        @return _length($result, str-slice($string, $i));
      }

      $number: nth($numbers, $index);

      @if $divider == 0 {
        $result: $result * 10;
      } @else {
        // Move the decimal dot to the left
        $divider: $divider * 10;
        $number: $number / $divider;
      }

      $result: $result + $number;
    }
  }

  @return if($minus, $result * -1, $result);
}

$compass-imagehelper-path-prefix: '/img/';

$compass-imagehelper-imagesmap: (
  'AEC_loading.jpg': (
    width: '1920',
    height: '1080',
    hash: '8d13bcadcf321ce43b025b1d47967b88'
  ),
  'arrow-down.png': (
    width: '56',
    height: '63',
    hash: '02044ad1a9ba28e3cf6acbc7d051f702'
  ),
  'asp-net.png': (
    width: '64',
    height: '64',
    hash: 'e04bed5b39d89a58bdb3849a2ff8fc87'
  ),
  'Banda-360-o.jpg': (
    width: '900',
    height: '196',
    hash: '96d402d5806ed6c651f6aeb45ceb3520'
  ),
  'Banda-360-v.jpg': (
    width: '283',
    height: '614',
    hash: '0f488b53ba79af5d35d3a481681b387c'
  ),
  'blog.png': (
    width: '64',
    height: '64',
    hash: 'f7891a5403c402960ecb0ae37decbc65'
  ),
  'cover.png': (
    width: '1600',
    height: '604',
    hash: '82d2f61c1448b310d9e0942ad0b87d46'
  ),
  'facebook.png': (
    width: '64',
    height: '64',
    hash: 'cb76fd023bb8bfb9dabae1b74019fd66'
  ),
  'forum.png': (
    width: '64',
    height: '64',
    hash: '4a403551d727257944069360bff39127'
  ),
  'globe.png': (
    width: '546',
    height: '480',
    hash: '396b2938f45c3eee4188c34fc1c5021e'
  ),
  'google-plus.png': (
    width: '64',
    height: '64',
    hash: 'd508e2a83065a72b52ed7754561bf3ce'
  ),
  'knowledge-base.png': (
    width: '64',
    height: '64',
    hash: '0c1ea46ae67d053a893854c4c4d4cc84'
  ),
  'logo.png': (
    width: '133',
    height: '100',
    hash: '4ecdddf53fa4b4b108649508cbd1c849'
  ),
  'next-arrow.png': (
    width: '72',
    height: '146',
    hash: '1f356c6a2f3accbed1a3bb1cdcac2540'
  ),
  'ok.png': (
    width: '32',
    height: '32',
    hash: 'f13d0d76f82202530e963634d8f13cae'
  ),
  'perl.png': (
    width: '64',
    height: '64',
    hash: '761b159d396294bb8c6d952f9351a6cb'
  ),
  'php.png': (
    width: '64',
    height: '64',
    hash: '5abb55bb92e559b1f2bc9cdc60fcde0f'
  ),
  'pin.png': (
    width: '25',
    height: '40',
    hash: '9a7bb0e2a3514440c285dadef2a42bc2'
  ),
  'placeholder.jpg': (
    width: '965',
    height: '543',
    hash: '0ac9fef8181cf4c2368d9dd866d2a23a'
  ),
  'play.png': (
    width: '150',
    height: '150',
    hash: 'b230798a4c9512a33bf3193c4adc2b88'
  ),
  'prev-arrow.png': (
    width: '72',
    height: '146',
    hash: 'faaa63aa78a03d36911a769ca7ff3341'
  ),
  'python.png': (
    width: '64',
    height: '64',
    hash: 'c574b44ad793e1bf33d42897bf9181ec'
  ),
  'silhouette-man-s.jpg': (
    width: '100',
    height: '99',
    hash: '76c0a7558ae20e5a02f059adfad1857e'
  ),
  'silhouette-man.jpg': (
    width: '294',
    height: '291',
    hash: '21805d4f42697f24faf0b90b947713c6'
  ),
  'silhouette-woman-s.jpg': (
    width: '626',
    height: '626',
    hash: 'be4feb0d407c0ed441efad8a93c1f365'
  ),
  'Skyrunning-CONI.jpg': (
    width: '2045',
    height: '1194',
    hash: 'fb1f8c5dbd43a1b9894273e5ad92196e'
  ),
  'twitter.png': (
    width: '64',
    height: '64',
    hash: '49f00313f6add19cd5da23ea1409fe05'
  ),
  'updateprogress.gif': (
    width: '48',
    height: '48',
    hash: '4801d074dbe5cdd7755af9e25de1dc6c'
  ),
  'user-female.png': (
    width: '300',
    height: '300',
    hash: '25be2d0cb75e32c7c67bbcb3401fde67'
  ),
  'user-male.png': (
    width: '300',
    height: '300',
    hash: '8a0655000d0cbf72768ae2d5f0df0d33'
  ),
  'user-unisex.png': (
    width: '300',
    height: '300',
    hash: '222717c7222d38d139e26aebeab1948a'
  ),
  'warning.png': (
    width: '32',
    height: '32',
    hash: '6f3aa0516c5077fb39a7b37dc8a9c7fb'
  ),
  'white_base.png': (
    width: '400',
    height: '300',
    hash: 'c86d3fcc5ebc4bb7d3981846f8b4820b'
  ),
  'wire-bottom.png': (
    width: '1920',
    height: '700',
    hash: 'f1a8353a6731dc084aa31ca9e82fcae4'
  ),
  'wire-dx.png': (
    width: '1728',
    height: '100',
    hash: '72fbefd3a0070b272dc63700aef25870'
  ),
  'wire-sx.png': (
    width: '1728',
    height: '100',
    hash: 'c4725f9d5f0190299bddaa3cf38ed63d'
  ),
);
