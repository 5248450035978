﻿/*#region FOOTER MAIN */

$footer-main-bg-color: $medium-gray;
$footer-main-text-color: $white;
$footer-main-anchor-color: $white;
$footer-main-anchor-color-hover: $anchor-color-hover;

/*#endregion */

/*#region FOOTER COPYRIGHT */

$footer-copyright-bg-color: $primary-color;
$footer-copyright-text-color: $extradark-gray;
$footer-copyright-anchor-color: $extradark-gray;
$footer-copyright-anchor-color-hover: $extradark-gray;

/*#endregion */

#footer {
    position: relative;

    .footer-main {
        background-color: $footer-main-bg-color;
        color: $footer-main-text-color;
        padding: rem-calc(20 0);
        font-weight: $light;

        @include breakpoint(large) {
            padding: rem-calc(30 0);
        }

        &, * {
            transition: color .2s;
        }

        a, a i {
            color: $footer-main-anchor-color;
        }

        a {
            color: $footer-main-anchor-color;

            &:hover, &:hover i {
                color: $footer-main-anchor-color-hover;
            }
        }

        .contacts {
            margin-bottom: rem-calc(5);

            @include breakpoint(small only) {
                text-align: center;
            }
        }

        .privacy-cookies {
            font-size: rem-calc(14);
            text-align: right;

            &, * {
                font-size: rem-calc(14);
            }


            @include breakpoint(small only) {
                margin-top: rem-calc(16);
                text-align: center;

                a {
                    font-size: rem-calc(12);
                }

                & > div {
                    display: inline-block;


                    &:not(:last-child):after {
                        content: "|";
                        padding: rem-calc(6);
                    }
                }
            }
        }

        .logo {
            max-width: rem-calc(190);
            display: block;
            margin: auto;
            text-align: center;
            margin-bottom: rem-calc(16);


            @include breakpoint(large) {
                max-width: rem-calc(260);
            }
        }

        #lnkLogin {
            i {
                vertical-align: baseline;
            }
        }
    }

    .footer-copyright {
        background-color: $footer-copyright-bg-color;
        color: $footer-copyright-text-color;
        padding: rem-calc(10 0);

        @include breakpoint(small only) {
            text-align: center;
            font-size: 90%;
        }

        @include breakpoint(medium) {
            padding: rem-calc( 20 0);
        }

        p {
            color: $footer-copyright-text-color;
            display: inline-block;
            margin: 0;
        }

        a {
            font-weight: bold;
            color: $footer-copyright-anchor-color;

            &:hover {
                color: $footer-copyright-anchor-color-hover;
            }
        }

        .legalInfo {
            &:before {
                content: "-";
                padding: rem-calc(0 5);
            }
        }

        #pnlCredits {
            font-size: rem-calc(12);

            @include breakpoint(medium) {
                text-align: right;
            }
        }
    }
}

/*#region FOOTER MENU*/

/*#region Variabili Top Bar */

$topbar-background: transparent; //$primary-color;
$topbar-padding: 30px 0 0;

$menu-item-padding: 1px 0;
$menu-item-margin: 0 0.5rem;

$menu-item-font-size: rem-calc(11);
$menu-item-text-transform: uppercase;
$menu-item-font-weight: normal;

$menu-item-color: $primary-color;
$menu-item-background: transparent;

$menu-item-color-hover: scale-color($primary-color, $lightness:30%);
$menu-item-background-hover: transparent;
$menu-item-hover-underline: true;

$menu-item-color-active: scale-color($primary-color, $lightness:30%);
$menu-item-background-active: transparent;
$menu-item-font-weight-active: 400;
$menu-item-active-underline: true;

$menu-item-color-active-hover: scale-color($primary-color, $lightness:30%);
$menu-item-background-active-hover: transparent;

/*#endregion */

#pnlFooterMenu {

    @include custom-menu;

    .top-bar {
        @include top-bar-container;
    }
}


/*#endregion*/
