﻿/*#region EDIT PAGE*/

#pnlEditPage {
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(15);

    * {
        transition: all .2s;
    }

    .button {
        color: $black;
        font-weight: $bold;
        margin: 0;

        i {
            color: inherit;
            margin-right: rem-calc(2);
        }
    }

    #lstLogout i {
        color: $alert-color;
        font-size: rem-calc(25);
        padding-left: rem-calc(10);
        transition: all .2s;

        &:hover {
            color: scale-color($alert-color, $lightness:30%);
        }
    }

    #lnkProfileUser {
        margin: 0 rem-calc(10);

        .name {
            font-weight: $bold;
        }

        .profilePic {
            border-radius: 35%;
            border: rem-calc(1) solid $medium-gray;
        }

        &:hover {
        }
    }

    #lnkEditUser {
        i {
            vertical-align: baseline;
        }
    }

    #pnlUser {
        #updPnlMaster {
            display: inline-block;
        }

        margin-right: 0;
        margin-left: auto;
    }
}

/*#endregion*/

/*#region HEADER (cover - profile pic - slave menu) */

$profilePic-width: rem-calc(190);
$profilePic-Xoffset: rem-calc(50);

#pnlUserHeader {
    margin-bottom: rem-calc(60);

    .header {
        position: relative;

        @include breakpoint(small only) {
            padding: 0;
        }

        #pnlCoverPic {
            position: relative;
            height: rem-calc(200);
            background-color: $primary-color;

            @include breakpoint(medium) {
                height: rem-calc(250);
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                @include background(linear-gradient(to bottom, transparent 65%, rgba($primary-color, 0.9) 95%, rgba($primary-color, 1) 100%));
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
        }

        #pnlProfilePic {
            z-index: 9;
            @include position($position:absolute, $center:true);
            top: auto;
            bottom: 0;

            @include breakpoint(medium) {
                transform: unset;
                left: $profilePic-Xoffset;
                top: auto;
                bottom: rem-calc(-50);
            }

            img {
                border-radius:35%;
                max-width: rem-calc(100);
                /*border: 1px solid black;*/
                @include breakpoint(medium) {
                    max-width: unset;
                }
            }
        }

        #pnlName {
            font-size: rem-calc(24);
            font-weight: $bold;
            text-align: center;
            margin-top: rem-calc(22);
            margin-bottom: rem-calc(15);

            @include breakpoint(medium) {
                position: absolute;
                bottom: rem-calc(10);
                left: $profilePic-width + $profilePic-Xoffset;
                color: $white;
                text-align: left;
                margin-top: rem-calc(25);
                margin-bottom: 0;
                @include text-shadow(black rem-calc(1) rem-calc(1) 0);
            }
        }
    }

    .slaveMenu {
        position: relative;

        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $dark-gray;
            margin-right: 0;
            margin-left: auto;
            display: block;

            @include breakpoint(medium) {
                width: 90%;
            }
        }

        .menu {

            @include breakpoint(medium) {
                padding-left: $profilePic-width + $profilePic-Xoffset - rem-calc(20);
            }

            background-color: transparent;
            border-top: 0;


            a {
                color: $anchor-color;
                font-weight: $normal;
                font-size: rem-calc(14);
                margin: .7rem 1rem .7rem 0;
                padding: 0;
                padding-bottom: 2px;
                transition: all .3s;
                border-bottom: 1px solid transparent;
                /*@include breakpoint(medium) {
                    font-size: rem-calc(17);
                }*/
            }

            li.active, li:hover {
                a {
                    color: $anchor-color-hover;
                    border-bottom: 1px solid $anchor-color-hover;
                    background-color: unset;
                }
            }
        }
    }
}

/*#endregion */

/*#region VERTICAL MENU*/
.profileVerticalMenu {
    border: rem-calc(1) solid $medium-gray;
    background-color: $white;
    padding-right: rem-calc(40);
    font-weight: $normal;
    margin-bottom: rem-calc(35);

    @include breakpoint(medium) {
        padding-right: rem-calc(40);
    }

    a {
        //color: $dark-gray;
        padding: rem-calc(4) 0;
        padding-left: rem-calc(10);
        margin: rem-calc(10);
        margin-left: 0;
        font-weight: $bold;
    }

    i {
        margin-left: rem-calc(10);
        margin-right: rem-calc(10);
        width: rem-calc(22);
    }

    li.active {

        a {
            @include text-styling($medium-gray, transparent);
            
            i {
                color: inherit;
            }
        }
    }
}
/*#endregion*/

/*#region USERS GRID*/
.usersGrid {

    .card {
        margin: 0;
        border: rem-calc(1) solid $medium-gray;
    }

    .card-custom {
        background-color: $white;
    }

    img {
        border-radius: 35%;
        background-color: $primary-color;
    }

    .button {
        margin: 0;
        text-transform: uppercase;
        font-size: rem-calc(12);
        font-weight: $bold;
        padding: rem-calc(5 8);
        border-radius: rem-calc(6);

        i {
            color: inherit;
            margin-right: rem-calc(5);
        }
    }

    .lnkUserName {



        .name {
            border-bottom: rem-calc(1) solid transparent;
            transition: all .2s;
        }

        font-weight: $normal;

        &:hover {
            .name {
                border-bottom: rem-calc(1) solid $anchor-color-hover;
            }
        }
    }
}
/*#endregion*/

/*#region USER INFO*/
.profile.card {
    @include breakpoint(large) {
        @include xy-cell(9);
    }

    border: rem-calc(1) solid $medium-gray;

    .card-divider {
        font-weight: $bold;
        font-size: rem-calc(22);
    }

    .info {
        padding: rem-calc(10 0);
        border-bottom: rem-calc(1) solid $light-gray;

        &:last-child {
            border-bottom: none;
        }
    }

    .field, .value {
        @include xy-cell(12);

        @include breakpoint(medium) {
            @include xy-cell(5);
        }

        display: inline-block;
        font-weight: $normal;
    }

    .field {
        color: $dark-gray;
    }

    .value {
        font-size: rem-calc(18);
    }
}
/*#endregion*/

/*#region EDIT USER INFO*/
#editProfile, #changePassword {
    .fieldset:first-of-type {
        margin-top: -rem-calc(15);
    }
}

#changePassword {

    #pnlChangePassword {
        @include breakpoint(large) {
            @include xy-cell(6);
        }
    }
}
/*#endregion*/

/*#region EDIT PROFILE DEVEXPRESS*/
$dragZone-width: rem-calc(350);
$dragZone-height: rem-calc(350);

.dropZoneExternal > div,
.dropZoneExternal > img {
    position: absolute;
}

.dropZoneExternal {
    position: relative;
    border: rem-calc(1) dashed $primary-color !important;
    cursor: pointer;

    img {
        max-width: $dragZone-width - rem-calc(10);
        max-height: $dragZone-height - rem-calc(10);
    }
}

.dropZoneExternal,
.dragZoneText {
    width: $dragZone-width;
    height: $dragZone-height;
}

.dropZoneText {
    width: $dragZone-width - rem-calc(50);
    height: $dragZone-height - rem-calc(50);
    color: #fff;
    background-color: #888;
}

#dropZoneProfile, #dropZoneCover {
    @include position(absolute, $center:true);
   
}

.uploadControlDropZone,
.hidden {
    display: none;
}

.dropZoneText,
.dragZoneText {
    /*display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 20pt;*/
    display: flex;
    vertical-align: middle;
    text-align: center;
    font-size: 20pt;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.dragZoneText {
    color: #808080;
    padding: rem-calc(10);
}

.dxucInlineDropZoneSys span {
    color: #fff !important;
    font-size: 10pt;
    font-weight: normal !important;
}

.uploadControlProgressBar {
    width: rem-calc(350) !important;
}

.validationMessage {
    padding: rem-calc(0 20);
    text-align: center;
}

.uploadControl {
    margin-top: rem-calc(10);
    width: $dragZone-width !important;

    tbody th, tbody td {
        padding: 0;
    }

    .dxucBrowseButton_MaterialCompact {
        padding: rem-calc(10) !important;
    }
}



/*#endregion*/

/*#region FRIENDS*/

#friendsVerticalMenu {

    li.active, a:hover {
        i:after {
            color: inherit;
        }
    }


    a {
        position: relative;
    }

    .fa-user.arrow-right {
        &:after {
            content: "\f061 ";
        }
    }

    .fa-user.arrow-left {
        &:after {
            content: "\f060 ";
        }
    }

    .fa-user {
        position: relative;
        font-size: rem-calc(20);


        &:after {
            color: $primary-color;
            @include position($position:absolute, $bottom:0, $left:rem-calc(10));
            font-size: rem-calc(10);
            background: white;
            border-radius: 50%;
            padding: rem-calc(1);
        }
    }
}

.friends {
    .container {
        @include media-object-container;
        @include flex-align($y: middle);
        margin: 0;

        .image, .text {
            @include media-object-section();
        }
    }
}

/*#endregion*/

.diary, .userPhoto {
    .photo img {
        border: rem-calc(2) solid $primary-color;
        border-width: rem-calc(2) !important;
    }

    h5 {
        font-size: rem-calc(20);
        font-weight: $normal;
        text-align: left;
        margin-bottom: rem-calc(2);
    }
}

/*#region DIARIO */

.diary {

    .panel {
        /*background: red;*/
        margin-bottom: rem-calc(20);
    }

    .photo {
        &.cell {
            margin-bottom: 0;
        }
    }

    .photoCarousel {
        @include xy-gutters($gutters:rem-calc(16), $gutter-type:margin,$negative:true);

        .photo {
            @include xy-gutters($gutters:rem-calc(16), $gutter-type:margin, $gutter-position:top bottom left right);
        }

        .slick-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem-calc(30);
            height: rem-calc(30);

            &:before {
                @include pseudo();
                width: rem-calc(10);
                height: rem-calc(10);
                border: rem-calc(2) solid $primary-color;
                transform: rotate(45deg);
            }
        }

        .slick-next {
            right: -rem-calc(15);

            &:before {
                border-left: 0;
                border-bottom: 0;
            }
        }

        .slick-prev {
            left: -rem-calc(15);

            &:before {
                border-right: 0;
                border-top: 0;
            }
        }
    }

    #pnlTopPhoto {
        .photoCarousel {
            @include xy-gutters($gutters:rem-calc(8), $gutter-type:margin,$negative:true);

            .photo {
                @include xy-gutters($gutters:rem-calc(8), $gutter-type:margin, $gutter-position:top bottom left right);
            }

            .slick-next {
                right: -rem-calc(20);
            }

            .slick-prev {
                left: -rem-calc(20);
            }
        }
    }

    .photosGrid {
        @include xy-grid;
        @include xy-gutters($gutters:rem-calc(16), $gutter-type:margin,$negative:true);
        @include xy-grid-layout($n:2, $gutters:rem-calc(16), $gutter-position:top right bottom left);
    }

    .followUsers {
        background-color: $light-gray;
        border: rem-calc(1) solid $medium-gray;
        border-left: rem-calc(5) solid $primary-color;
        padding: rem-calc(10);

        .usersGrid > .cell {
            margin-top: rem-calc(10);
            margin-bottom: rem-calc(10);
        }

        .card {
            border: none;
            background-color: transparent;

            .card-section {
                padding: 0;
            }

            .cell {
                width: 100%;
            }
        }


        .lnkUserName {
            .image, .text {
                text-align: center;
            }

            .image {
                position: relative;
                bottom: -rem-calc(20);
            }

            img {
                width: auto !important;
                max-width: rem-calc(60);
                margin-bottom: rem-calc(2);
            }

            .text {
                margin: auto;
                font-size: rem-calc(14);
                line-height: 1.2;
                padding: rem-calc(22 5 6);
                border: rem-calc(1) solid $medium-gray;
                background-color: scale-color($light-gray, $lightness: 50%);
                transition: all .8s;
            }

            .name {
                border: none;
            }

            &:hover {
                .text {
                    border: rem-calc(1) solid $dark-gray;
                }

                .name {
                    border: none;
                }
            }
        }
    }
}

/*#endregion */

/*#region PHOTO*/

.photo {

    *,
    &:after,
    &:before {
        transition: all .2s;
        transition-delay: 0;
    }

    padding: rem-calc(2);
    margin-bottom: 0 !important;

    .wrapper {
        position: relative;
        display: block;

        &:after {
            content: "";
            @include position($position:absolute, $left:50%, $bottom:0);
            transform: translateX(-50%);
            width: 100%;
            height: 30%;
            @include background(linear-gradient(to top, rgba($black, 0.5) 20%, transparent 100%));
            opacity: 0;
        }
    }

    .like-area {
        @include position($position:absolute, $bottom:0, $left:0);
        width: 100%;
        text-align: right;
        z-index: 9;
        opacity: 0;

        & > div {
            display: inline-block;
        }

        .ltrLikeCount {
            color: white;
            vertical-align: sub;
        }
    }

    .button {
        margin: 0;

        &.delete {
            @include position($position:absolute, $top:rem-calc(10), $right:rem-calc(10));
            padding: rem-calc(2 6);
            background-color: white;
            border: rem-calc(1) solid $primary-color;
            opacity: 0;
            z-index: 1;
        }

        &.like {
          
            background-color: transparent;
            padding: rem-calc(10);
            padding-left: rem-calc(2);


            i {
                color: $white;
            }
        }
    }


    &:hover {
        .wrapper:after {
            opacity: 1;
        }

        .like-area {
            opacity: 1;
        }

        .button {
            opacity: 1;
        }
    }
}

.userPhoto {

    .grid-container {
        position: relative;
    }

    .album {
        @include get-map-value(margin-bottom, $grid-column-gutter);
        @include get-map-value(margin-top, $grid-column-gutter);
    }

    #lnkLoadButton {
        /*position: absolute;
        margin: 0;*/
        padding: .6rem .5rem;
        font-size: rem-calc(14);


        $margin: rem-calc(map-get($grid-column-gutter, small));
        right: ($margin / 2) !important;
        top: $margin;

        @include breakpoint(medium) {
            $margin: rem-calc(map-get($grid-column-gutter, medium));
            right: ($margin / 2) !important;
            top: $margin;
        }

        i {
            color: inherit;
            margin-left: .2rem;
            vertical-align: text-top;
        }
    }
}
/*#endregion*/

.fixed-progress {
    background-color: rgba($black, .5);
    @include position($position:fixed, $center:true);
    width: 100%;
    height: 100%;
    z-index: 999;

    .content {
        @include position($position:fixed, $center:true);
        //background-color: white;
        padding: 2rem;
    }

    i {
        font-size: 5rem;
        color: white;
    }
}
